import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'index',
    // component: () => import('../views/Index.vue')
    redirect: 'upload'
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import('../views/Upload.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/share/:shareId',
    name: 'share',
    component: () => import('../views/Share.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
