import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import VueClipboard from 'vue-clipboard2'
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import MarkdownItVue from "markdown-it-vue";
import "markdown-it-vue/dist/markdown-it-vue.css";

Vue.use(MarkdownItVue)
Vue.use(VueClipboard)
axios.defaults.baseURL = 'https://api.dailyresource.online/shareapi/'
// axios.defaults.baseURL = 'http://localhost:9000/shareapi/'
// 设置axios的请求头
axios.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json; charset=utf-8'
  return config
})
Vue.prototype.$http = axios
Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
